import React from 'react'
import Styles from './index.module.css'

const SmartSence = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>关键场景  解锁智能</div>
      <div className={Styles.subTitle}>深入了解物业管理服务难题，全方位升级空间智能化服务能力</div>
      <div className={Styles.group}>
        <div className={Styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/8439719e82c46f0ab039e36baa9083a7"
            alt=""
            
          />
          <div className={Styles.itemTitle}>智慧停车</div>
        </div>
        <div className={Styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/ff5991cb2105c3a29c78b3d29448fba5"
            alt=""
            
          />
          <div className={Styles.itemTitle}>智慧门禁</div>
        </div>
        <div className={Styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/57d0bacb32ccccdfea5e91fd5004da6f"
            alt=""
            
          />
          <div className={Styles.itemTitle}>报事报修</div>
        </div>
        <div className={Styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/659b942fc0cf226e1a163b14ea236b43"
            alt=""
            
          />
          <div className={Styles.itemTitle}>在线缴费</div>
        </div>
        <div className={Styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/d73964a2d4f4337b6273396fee42e92e"
            alt=""
            
          />
          <div className={Styles.itemTitle}>服务预约</div>
        </div>
        <div className={Styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/c5148d9a5895b5514ecfa3ed429fc4a6"
            alt=""
            
          />
          <div className={Styles.itemTitle}>政府办事</div>
        </div>
      </div>
    </div>
  )
}

export default SmartSence