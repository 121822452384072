import React from "react"
import LayoutMobile from "../components-mobile/layout/index"
import Banner from "../components-mobile/home/banner"
import Win from "../components-mobile/home/win"
import Clound from "../components-mobile/home/clound"
import Core from "../components-mobile/home/core"
import SmartSence from "../components-mobile/home/smart-sence"
import Customers from "../components-mobile/home/customers"
import Friends from "../components-mobile/home/friends"




const IndexMobilePage = () => {
  const bannerJson ={
    title:'数据驱动，技术赋能',
    subTitle:'打造联接每一户的美好社区体验',
    bannerUrl:'http://s.lianmeihu.com/x-uploader/image/2021-07-08/888a70fbd3502c095b4530e8d9775068',
    imgUrl:'http://s.lianmeihu.com/x-uploader/image/2021-07-08/91c02391af754ef00f2789404c28039e',
    iconUrl:'http://s.lianmeihu.com/x-uploader/image/2021-07-08/0c88e09265c0af1cee8cad6d3596aeb1'
  }

  return (
    <LayoutMobile page="Home">
      <Banner json={bannerJson} />
      <Win />
      <Clound />
      <Core />
      <SmartSence />
      <Customers />
      <Friends />
    </LayoutMobile>
  )
}
export default IndexMobilePage