import React from 'react'
import styles from './index.module.css'

const Friends = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>合作伙伴</div>
      <div className={styles.subTitle}>携手领先的伙伴，共创品质服务</div>
      <div className={styles.group}>
        <div className={styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/6cefcf2e819f4518adac7ef86491cf02"
            alt=""
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <div className={styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/df819db999ba720f66845c6a3d64afa0"
            alt=""
           style={{ width: '100%', height: '100%'}}
          />
        </div>
        <div className={styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/6bd08a6db1aaa5d474c420a85d22f943"
            alt=""
           style={{ width: '100%', height: '100%'}}
          />
        </div>
        <div className={styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/d8d4d85d4007b3aae1a0c859fe707f71"
            alt=""
           style={{ width: '100%', height: '100%'}}
          />
        </div>
        <div className={styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/8813966702764af545c5bd4f9e0c3bfb"
            alt=""
           style={{ width: '100%', height: '100%'}}
          />
        </div>
        <div className={styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/eb295c7185411a5e283bfa54dd3b6921"
            alt=""
           style={{ width: '100%', height: '100%'}}
          />
        </div>
        <div className={styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/02278d569205b2c9f764cfb1e2ee8797"
            alt=""
           style={{ width: '100%', height: '100%'}}
          />
        </div>
        <div className={styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/b90af333d71d5e6b411be8a3bf867059"
            alt=""
           style={{ width: '100%', height: '100%'}}
          />
        </div>
      </div>
    </div>
  )
}

export default Friends