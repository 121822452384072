import React from 'react'
import Styles from './index.module.css'

const Core = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>核心优势  助力增长</div>
      <div className={Styles.subTitle}>以技术赋能，用数据驱动，借生态助力</div>
      <div className={Styles.group}>
        <div className={Styles.item}>
          <div className={Styles.icon}>
            <img
              src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/697be3d083b92deb274fb42f8b54d446"
              alt=""
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div className={Styles.iconTitle}>系统化解决方案</div>
          <div className={Styles.iconSubTitle}>提供端云一体、软硬配套等系统化方案支持传统社区快速高效地实现全面升级</div>
        </div>
        <div className={Styles.item}>
          <div className={Styles.icon}>
            <img
              src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/ab32874231f074bb4b24e296ed6dcd19"
              alt=""
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div className={Styles.iconTitle}>社区数据闭环</div>
          <div className={Styles.iconSubTitle}>打造人-空间-服务数据闭环，实现数字化社区全景运营</div>
        </div>
        <div className={Styles.item}>
          <div className={Styles.icon}>
            <img
              src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/779d3b6253c7e0dc42132e0665c2ae27"
              alt=""
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div className={Styles.iconTitle}>实力合作伙伴</div>
          <div className={Styles.iconSubTitle}>与阿里人居平台深度合作，联手业界领先合作伙伴，缔造智能、安全社区</div>
        </div>
      </div>
    </div>
  )
}

export default Core