import React from 'react'
import Styles from './index.module.css'

const Customers = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>服务对象</div>
      <div className={Styles.subTitle}>
        为社区空间运营、管理、服务者提供优质的方案服务
      </div>
      <div className={Styles.group}>
        <div className={Styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/cd749c443993fbb1dc13338964f6f567"
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
          <p>地产开发商</p>
        </div>
        <div className={Styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/88c6518ad19a7103084cc0d1e859c5ff"
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
          <p>物业公司</p>
        </div>
        <div className={Styles.item}>
          <img
            src="http://s.lianmeihu.com/x-uploader/image/2021-07-09/dec46dabd85b4107809e2316068fd574"
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
          <p>政府机构</p>
        </div>
      </div>
    </div>
  )
}

export default Customers