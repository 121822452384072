import React from 'react'
import styles from './index.module.css'

const Clound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>端云一体 高度集成</div>
      <div className={styles.subTitle}>
        领先的系统化方案能力，全面交付社区智能化所需
      </div>
      <div className={styles.img1}>
        <img
          src={
            "http://s.lianmeihu.com/x-uploader/image/2021-07-08/ab9c45b9afc9120a374e2096d18c2d9d"
          }
          alt=""
          style={{width: '100%',height: '100%'}}
        />
      </div>
    </div>
  )
}

export default Clound;