import React from 'react';
import styles from './index.module.css'

const Win = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>聚焦痛点 多方共赢</div>
      <div className={styles.subTitle}>
        依托物联网技术与大数据服务，实现企业、商家与<br />政府机构的降本增效，全面提高业主满意度
      </div>
      <div className={styles.group}>
        <div className={styles.item}>
          <img
            src={
              "http://s.lianmeihu.com/x-uploader/image/2021-07-08/dcd411b48edac8ea84db7be838243e1f"
            }
            alt=""
            
          />
          <div className={styles.itemTitle}>物业公司</div>
          <div className={styles.itemText}>社区运维效率低、成本高，与业主沟通受限</div>
        </div>
        <div className={styles.item}>
          <img
            src={
              "http://s.lianmeihu.com/x-uploader/image/2021-07-08/8374768d1a6a53346fb281be11cb4e4d"
            }
            alt=""
            
          />
          <div className={styles.itemTitle}>企业商户</div>
          <div className={styles.itemText}>社区周边业态同质化严重，商家营销难</div>
        </div>
        <div className={styles.item}>
          <img
            src={
              "http://s.lianmeihu.com/x-uploader/image/2021-07-08/6b63fbe1d7d62c38a9566d94bff77575"
            }
            alt=""
            
          />
          <div className={styles.itemTitle}>政府机构</div>
          <div className={styles.itemText}>政府办事直通车，行政服务更高效</div>
        </div>
        <div className={styles.item}>
          <img
            src={
              "http://s.lianmeihu.com/x-uploader/image/2021-07-08/94c17390111afe96a4b0a8e59a39d72e"
            }
            alt=""
            
          />
          <div className={styles.itemTitle}>社区业主</div>
          <div className={styles.itemText}>社区设施老旧，生活不便，物业服务不到位</div>
        </div>
      </div>
    </div>
  )
}

export default Win;